import style from './home.module.scss';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Head from 'next/head';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Home(props) {

  //Handles slide transition when the application is in mobile or tablet view
  const [currentSlide, setCurrentSlide] = useState(0)
  useEffect (() => {
    let interval = null
    if (props.windowSize !== "Web") {
      interval = setInterval(() => {
        if (currentSlide < 2) {
          setCurrentSlide(currentSlide + 1)
        } else {
          setCurrentSlide(0)
        }
      }, 5000)


      return () => {
          clearInterval(interval)
      }
    }
  }) 

  //Set the window size state to maintain media queries
  const [gridSize, setGridSize] = useState(0)
  
  useEffect (() => {
		const handleGridResize = () => {
      let limit = 0
			if (props.windowSize === "Web") {
        if (window.matchMedia("(max-width: 1300px)").matches) {
          //set column limit
          limit = 3
        } else {
          //set column limit
          limit = 4
        }
      } else if (props.windowSize === "Tablet") {
        if (window.matchMedia("(max-width: 600px)").matches) {
          //set column limit
          limit = 2
        } else {
          //set column limit
          limit = 3
        }
      } else {
        //set column limit
        limit = 8

      }

			if (limit !== gridSize) {
				setGridSize(limit)
			}
		}
		
		window.addEventListener('resize', handleGridResize)

		handleGridResize()

		return () => {
			window.removeEventListener('resize', handleGridResize)
		}
  }) 

  //check whether to render webp or png
  let imageType = "png"
  if (props.webp === "webp") {
    imageType = "webp"
  }

  const standyGenerators = <div className={`${style.imageContainer} ${currentSlide === 0 && props.windowSize !== "Web" ? style.active : ""} ${style.standbyGenerators}`}>
    <Image 
      src={"/Assets/Images/Standby_Diesel_Generators." + imageType}
      alt="Standby Diesel Generators"
      layout="fill"
      objectFit="cover"
    />
  </div>
  const waterPlant = <div className={`${style.imageContainer} ${currentSlide === 1 && props.windowSize !== "Web" ? style.active : ""} ${style.waterPlant}`}>
    <Image 
      src={"/Assets/Images/Water_Treatment_Plant." + imageType}
      alt="Water Treatment Plant"
      layout="fill"
      objectFit="cover"
    />
  </div>
  const powerPlant = <div className={`${style.imageContainer} ${currentSlide === 2 && props.windowSize !== "Web" ? style.active : ""} ${style.powerPlant}`}>
    <Image 
      src={"/Assets/Images/Electric_Power_Plant." + imageType}
      alt="Electric_Power_Plant"
      layout="fill"
      objectPosition="right bottom"
      objectFit="cover"
    />
  </div>

  //Handles css grid layout for IE
  let gridArr = []
  let colCount = 1
  let rowCount = 1
  for (let i = 0; i < 16; i += 2) {
    let limit = 0
    if (props.windowSize === "Web") {
      if (window.matchMedia("(max-width: 1300px)").matches) {
        gridArr[i] = rowCount
        gridArr[i + 1] = colCount
      } else {
        gridArr[i] = rowCount
        gridArr[i + 1] = colCount
      }
    } else if (props.windowSize === "Tablet") {
      if (window.matchMedia("(max-width: 600px)").matches) {
        gridArr[i] = rowCount
        gridArr[i + 1] = colCount
      } else {
        gridArr[i] = rowCount
        gridArr[i + 1] = colCount
      }
    } else {
      gridArr[i] = rowCount
      gridArr[i + 1] = colCount
    }

    //Check if column limit has been reached
    if (colCount === gridSize) {
      colCount = 1
      rowCount++
    } else {
      colCount++
    }
  }

  return (
    <div className={style.Home}>
      <Head>
        <title>Home | Basler Services</title>
			</Head>

      <div className={style.header}>
        <Image 
          className={style.background}
          src={"/Assets/Images/Yellow_and_white_hardhats." + imageType}
          alt="Yellow and white hardhat background for header"
          layout="fill"
          objectPosition="left bottom"
          objectFit="cover"
          priority
        />
        <div className={style.overlay}></div>
        <div className={style.logoContainer}>
          <Image 
            alt="white E2 logo" 
            src={"/Assets/Images/BS_White_Logo." + imageType}
            layout="fill"
            priority
          />
        </div>
        <h3>A Turnkey Electrical Engineering<br></br>and Field Services Company</h3>
        <Link href={'/e2-engineering-services'}><a className={`${style.cta} ${style.primaryCta}`}>View E&#178; Services</a></Link>
        <Link href={'/segrity-products-and-services'}><a className={`${style.cta} ${style.secondaryCta}`}>View SEGRITY Services</a></Link> 
      </div>
      
      <div className={style.featuredServices}>
        <div className={style.styleBar}></div>
        <h2>Featured Services</h2>
        <div className={style.tileContainer}>
          <Link href={'/e2-engineering-services/turnkey-installation-upgrades/excitation-system-upgrades'} >
            <a className={style.tile} style={{msGridRow: gridArr[0], gridRow: gridArr[0], msGridColumn: gridArr[1], gridColumn: gridArr[1]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Dual_ECM_2_Cabinet." + imageType}
                  alt="Engineer next to cabinet"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>Excitation System Upgrades</h6>
            </a>
          </Link>
          <Link href={'/e2-engineering-services/electrical-system-design/system-integration-and-design'}>
            <a className={style.tile} style={{msGridRow: gridArr[2], gridRow: gridArr[2], msGridColumn: gridArr[3], gridColumn: gridArr[3]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Man_at_computer." + imageType}
                  alt="Engineer at computer"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>System Integration and Design</h6>
            </a>
          </Link>
          <Link href={'/e2-engineering-services/consulting-services/system-evaluation-and-enhancement-studies'}>
            <a className={style.tile} style={{msGridRow: gridArr[4], gridRow: gridArr[4], msGridColumn: gridArr[5], gridColumn: gridArr[5]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Engineering_people_plans_on_glass." + imageType}
                  alt="Engineer people plan on glass board"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>System Evaluation and Enhancement Studies</h6>
            </a> 
          </Link>
          <Link href={'/e2-engineering-services/consulting-services/arc-flash-hazard-evaluation'}>
            <a className={style.tile} style={{msGridRow: gridArr[6], gridRow: gridArr[6], msGridColumn: gridArr[7], gridColumn: gridArr[7]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Business_project_team." + imageType}
                  alt="Business project team"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>Arc Flash Hazard Evaluation</h6>
            </a> 
          </Link>
          <Link href={'/e2-engineering-services/onsite-services/system-maintenance-and-testing'}>
            <a className={style.tile} style={{msGridRow: gridArr[8], gridRow: gridArr[8], msGridColumn: gridArr[9], gridColumn: gridArr[9]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Two_senior_engineers." + imageType}
                  alt="Two senior engineers"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>System Maintenance and Testing</h6>
            </a>
          </Link>
          <Link href={'/e2-engineering-services/turnkey-installation-upgrades/protection-systems'}>
            <a className={style.tile} style={{msGridRow: gridArr[10], gridRow: gridArr[10], msGridColumn: gridArr[11], gridColumn: gridArr[11]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/excitation_system_engineers." + imageType}
                  alt="Excitation system engineers"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>Protection System Upgrades</h6>
            </a>
          </Link>
          <Link href={'/e2-engineering-services/onsite-services/technical-training'}>
            <a className={style.tile} style={{msGridRow: gridArr[12], gridRow: gridArr[12], msGridColumn: gridArr[13], gridColumn: gridArr[13]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Working_on_blueprint." + imageType}
                  alt="Team working on blueprints"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>Technical Training</h6>
            </a>
          </Link>
          <Link href={'/e2-engineering-services/onsite-services/nerc-compliance-testing'}>
            <a className={style.tile} style={{msGridRow: gridArr[14], gridRow: gridArr[14], msGridColumn: gridArr[15], gridColumn: gridArr[15]}}>
              <div className={style.tileImage}>
                <Image 
                  className={style.background}
                  src={"/Assets/Images/Head_of_project_holds_laptop." + imageType}
                  alt="Engineers discussing project"
                  layout="fill"
                  objectFit="cover"
                />
                <div className={style.overlay}></div>
              </div>
              <span className={style.descriptionStyleBar}></span>
              <h6>NERC Compliance Testing</h6>
            </a>   
          </Link>
          <img className={style.styleGridTwo} alt="Repeat Grid 2" src={'/Assets/Images/Repeat_Grid_2.' + imageType}/>
          <img className={style.styleGridThree} alt="Repeat Grid 3" src={'/Assets/Images/Repeat_Grid_3.' + imageType}/>
          <img className={style.styleGridOne} alt="Repeat Grid 1" src={'/Assets/Images/Repeat_Grid_1.' + imageType}/>
        </div>
      </div>
      <div className={style.whyWeExcel}>
        <h2>Why We Excel</h2>
        <div className={style.infoContainer}>
          <div className={style.leftCell}>
            <p>Basler Services, LLC, formerly E&#178; Power Systems, was acquired in 2017 by Basler Electric to better meet customers&#39; needs by offering seamless solutions for both product and engineering services. In 2021, we acquired SEGRITY, a company specializing in hydro governors, unit and plant controls, control systems installation and commissioning.</p>
            <div className={style.logoContainer}>
                {/* <Image 
                  src={"/Assets/Images/Basler_Logo." + imageType}
                  alt="Basler Logo"
                  layout="fill"
                  objectFit="contain"
                />  */}
                <img className={style.baslerLogo} alt="Basler Logo" src={'/Assets/Images/Basler_Logo.' + imageType} />
            </div>
            <div className={style.logoContainer}>
              <img className={style.baslerLogo} alt="E2 Logo" src={'/Assets/Images/E2PS_Logo.' + imageType} /> 
              <img className={style.baslerLogo} alt="SEGRITY Logo" src={'/Assets/Images/SEGRITY_Logo.' + imageType} />
              {/* <div className={style.logoBox}>
                <Image 
                  src={"/Assets/Images/E2PS_Logo." + imageType}
                  alt="E2 Logo"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <div className={style.logoBox}>
                <Image 
                  src={"/Assets/Images/SEGRITY_Logo." + imageType}
                  alt="SEGRITY Logo"
                  layout="fill"
                  objectFit="contain"
                />
              </div>   */}  
            </div> 
            <p>We are a leading full-service electrical engineering and field services company, recognized for our knowledge, superior work and excellent customer service. Our engineers and technicians have broad systems and equipment knowledge, giving them the unique ability to provide excellent service for your project, no matter the size.</p>
            {props.windowSize !== "Web" ? <Link href={'/about-us'}><a className={`${style.cta} ${style.primaryCta}`}>More About Us <FontAwesomeIcon icon={['far', 'long-arrow-right']} /></a></Link> : ""}
          </div>
          <div className={style.rightCell}>
            <Image 
              className={style.background}
              src={"/Assets/Images/E2_Office." + imageType}
              alt="Basler Services office"
              layout="fill"
              objectFit="cover"
            />
            {props.windowSize === "Web" ? <div className={style.ctaContainer}><Link href={'/about-us'}><a className={`${style.cta} ${style.primaryCta}`}>More About Us <FontAwesomeIcon icon={['far', 'long-arrow-right']} /></a></Link></div> : ""}
          </div>
        </div>
      </div>
      <div className={style.marketsWeServe}>
        <div className={style.leftCell}>
          <h2>Markets We Serve</h2>
          {props.windowSize === "Mobile" ? 
            <div className={style.mobileImageContainer}>
              {standyGenerators}
              {waterPlant}
              {powerPlant}
            </div> : ""
          }
          <div>We take pride in our ability to serve customers in a wide variety of markets. Whether it&#39;s power generation, industrial services or genset applications, we manage your total project. From feasibility and design to commissioning your system, Basler Services, LLC is the solution for all your engineering and installation needs.</div>
          <div className={`${props.windowSize === "Web" ? style.listContainer : ''} ${props.windowSize === "Mobile" ? style.mobileColumn : ''}`}>
            <h5>Power Generation</h5>
            <ul>
              <li>Hydro Powered</li>
              <li>Gas Turbine</li>
              <li>Steam Turbine</li>
              <li>Combined Cycle</li>
            </ul>
          </div>
          <div className={`${props.windowSize === "Web" ? style.listContainer : ''} ${props.windowSize === "Mobile" ? style.mobileColumn : ''}`}>
            <h5>Industrial Services</h5>
            <ul>
              <li>Refineries</li>
              <li>Water Management</li>
              <li>Waste to Energy</li>
              <li>Chemical Plants</li>
            </ul>
          </div>
          <div className={props.windowSize === "Mobile" ? style.mobileColumn : ''}>
            <h5>Genset Applications</h5>
            <ul>
              <li>Emergency Standby</li>
              <li>Prime Power</li>
              <li>Peak Demand</li>
              <li>Microgrid </li>
            </ul>
          </div>
          <div className={style.ctaContainer}>
            <Link href={'/contact-us'}><a className={`${style.cta} ${style.primaryCta}`}>Contact Us <FontAwesomeIcon icon={['far', 'long-arrow-right']} /></a></Link>
          </div>
        </div>
        <div className={style.trapezoid}></div>
        {props.windowSize !== "Mobile" ? standyGenerators : ""}
        {props.windowSize !== "Mobile" ? waterPlant : ""}
        {props.windowSize !== "Mobile" ? powerPlant : ""}
      </div>
    </div>
  );
}

export default Home;